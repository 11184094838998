import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { ApiRequest, UrlParams } from 'library/APICall.js';
import { ResponseSnackbar, ShowSnackbar } from 'layout/snackbar/Snackbar';
import { AlertMessage } from 'layout/alert/Alert';
import { setCookie, uuidv4 } from 'library/Library';
import useModalStack, { ModalPopUp } from 'hooks/useModalStack';
import '../../styles/scss/loginpage.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset, faPhone, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export function LogoutUser(force_logout) {
    ApiRequest({ TYPE:'POST', URL:'/app/setup/Users/logout', CALLBACK:function(res){

    }, COMPLETE:function(){
        var id=localStorage.id;
        localStorage.clear();
        if(id!=null && id!="" && id!=undefined){
            
            localStorage.id=id;
        }
        window.location.reload();
    }, DATA:{
        force_logout:force_logout
    } })
    
}

export function LogoutUserAlert(force_logout){
    if(force_logout)
        AlertMessage({type:'warning',title:'Logout & Forget Device?',message:'Are you sure you want to logout & forget device?',btnTypeId:2,
            btnCancelText:"No",
            btnConfirmText:"Yes",
            confirmCallBack:()=>{LogoutUser(true)}
        })
    else
        LogoutUser(false)
}

export function ShowLoginForm() {
    ReactDOM.unmountComponentAtNode(document.getElementById('app-login'));
    const element = (
        <Login />
    );
    ReactDOM.render(element, document.getElementById('app-login'));
}

// function showNextForm() {
//     var currentForm = document.querySelector('.as-login-container');
//     currentForm.classList.toggle('as-active-form');
// }

function Login(props) {

    const [propertyName,setPropertyName]=useState('');
    const [patchVersion,setPatchVersion]=useState('');

    

    const [username, setUsername] = useState((localStorage.user_name == undefined ? '' : localStorage.user_name));
    const [password, setPassword] = useState('');
    const [otp, setOTP] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [otpError, setOTPError] = useState('');

    const [isOTPEnabled,setIsOTPEnabled]=useState(false);

    const [loginActiveClass, setLoginActiveClass] = useState('');

    const [licenseKey, setLicenseKey] = useState('');

    const [showSpinner, setShowSpinner] = useState(false);

    const usernameRef = useRef(null);
    const passwordRef = useRef(null);
    const otpRef = useRef(null);
    const licenseKeyRef = useRef(null);


    useEffect(() => {
        var id=localStorage.id;
        localStorage.clear();
        if(id!=null && id!="" && id!=undefined){
            
            localStorage.id=id;
        }
        usernameRef.current.focus();
        if (username != '')
            passwordRef.current.focus();

        LoadBaseData()
    }, []);

    const ProceedLogin = (event) => {
        event.preventDefault();
        if(loginActiveClass!="") return;//It is license key submission
        setCookie("_ASuL_",uuidv4());//used to disable login after browser close
        
        

        //ShowSnackbar('error','You are not logged out. Please login to continue.');
        //return;
        if (username == "") {
            setUsernameError('Please enter username.');
            return;
        } else if (password == "") {
            setPasswordError('Please enter password.')
            return;
        }else if ( isOTPEnabled && otp == "") {
            setOTPError('Please enter OTP.')
            return;
        }
        setShowSpinner(true);
        var cookie_otp=localStorage.id||""
        var inputdata = {
            "user_name": username,
            "password": password,
            "otp":(otp==""?null:otp),
            "id":cookie_otp,
        };
        ApiRequest({
            TYPE: 'POST',
            URL: "/app/setup/Users/login",
            CALLBACK: function (res) {

                if(res.error_code==1){
                    ResponseSnackbar(res);
                    return;
                }

                if(res.error_code==3){
                    //If OTP is already enabled, do not go into this because User have already 
                    if(res.data.is_otp_enabled){
                        if(isOTPEnabled){
                            
                        }else{
                            ShowSnackbar("info",res.message,10000);
                            setIsOTPEnabled(true);
                            setCookie("_X32ArS_","");//remove otp session if there is any
                            return;
                        }
                        
                    }
                }
                if (res.error_code == 0) {

                    try {
                        
                        localStorage.full_name = res.data.full_name;
                        localStorage.token = res.data.jwt_token;
                        localStorage.user_name = res.data.user_name;
                        localStorage.force_password_change = res.data.force_password_change;
                        localStorage.default_module = res.data.default_module;
                        localStorage.date_format = (res.data.date_format == undefined ? null : res.data.date_format);

                        if(res.data.new_session_for_otp!=undefined){
                            localStorage.id=res.data.new_session_for_otp;
                        }
                        ShowSnackbar("success", "Logged in successfully.");

                        ReactDOM.unmountComponentAtNode(document.getElementById('app-login'));
                        
                        if(window.location.origin+"/"!=window.location.href){//If user is logging in from different page.
                            window.location.reload(true);
                        } else{
                            if(localStorage.default_module=="null") localStorage.default_module="/";
                            else localStorage.default_module="/"+localStorage.default_module+"/";
                            window.location.reload(true);//Todo open dashboard of user default module.
                        }

                    } catch (error) {

                    }
                } else {
                    setShowSpinner(false);
                    setPassword("");
                    ResponseSnackbar(res);
                }


            }, COMPLETE: function () {
                setShowSpinner(false);
            }, DATA: inputdata
        });
    }

    const LoadBaseData=()=>{

        var params = UrlParams({
            unauth: true,
        });
        setCookie("_ASuL_",uuidv4());//used to disable login after browser close
        ApiRequest({ TYPE:'GET', URL:'/Application/appbase?'+params, CALLBACK:function(response){
            if(response.error_code==1) return;
            setPropertyName(response.data.property_name+" "+response.data.multi_property_code)
            setPatchVersion(response.data.PatchVersion);
          }, COMPLETE:function(){
            
          }, DATA:{} })
        }
    

    const [modal,push,pop] = useModalStack()

    //  For License Renew
    const OpenLicensePopup = () => {
        push(   //  Then push Modal to display.
            <ModalPopUp
                size = {'small'}
                header = {<span className="as-modal-popup-header">Renew License</span>}
                body={
                    <>
                        Please renew your license before it expires.
                    </>
                }
                footer={
                    <>
                        <button
                            type="button"
                            className="as-default-btn-solid-gray"
                            onClick={() => {
                                pop()
                            }}
                        >
                            Close
                        </button>
                    </>
                }
                pop = {() => {
                    pop()
                }}
                key = {1}
            />
        );
    }

    const LicenseRenew = ( )=> {
        setShowSpinner(true);
                var inputdata={
                    "license_key": licenseKey
                  }

                  ApiRequest({ TYPE:'POST', URL:'/app/tools/License/licenserenew', CALLBACK:function(res){
                    ResponseSnackbar(res);
                    if(res.error_code == 1)
                        return;
                    setLicenseKey('')
                  }, COMPLETE:function(){
                    
                    setShowSpinner(false);
                  }, DATA:inputdata })
    }

    //  Aegis Software SVG Logo
    const AegisSoftwareLogo = () => {
        return (
            <svg className='as-software-logo' viewBox="0 0 220.69 60.49">
                <g>
                    <path className='highlight' d="M208,6.62a48.35,48.35,0,0,1-.41,6.28c-.07.62-.17,1.26-.29,1.87a48.29,48.29,0,0,1-23.44,33.66,49.24,49.24,0,0,1-7.69-5.52A14,14,0,0,0,178,38a16.31,16.31,0,0,0,.35-3.39,11.77,11.77,0,0,0-5.91-10.45h7.1l.5-2.28a10.8,10.8,0,0,0,.22-2.55c0-6.33-4.37-11-11.32-12.77a25.47,25.47,0,0,0-6.37-.75h0q-.93,0-1.8.06A48.22,48.22,0,0,1,208,6.47C208,6.53,208,6.57,208,6.62Z" />
                    <path className='highlight' d="M144.34,9.54l-.72,3.69A62.6,62.6,0,0,0,129.29,24.7l3-15.16Z" />
                    <path d="M124.9,58.53a2.8,2.8,0,0,1,0-.4h1.71c0,.52.23.8.8.8s.89-.17,1-.52a.69.69,0,0,0,0-.13c0-.31-.31-.52-1-.7-1.24-.33-1.84-.95-1.84-1.85a2.61,2.61,0,0,1,0-.48c.24-1.2,1.22-1.86,2.78-1.86,1.39,0,2.29.76,2.29,1.91,0,.1,0,.21,0,.32H129c0-.47-.26-.73-.84-.73s-.75.16-.81.49a.78.78,0,0,0,0,.14c0,.31.29.5,1.13.74,1.23.35,1.82,1,1.82,1.86a3.42,3.42,0,0,1,0,.44c-.26,1.27-1.26,1.9-2.95,1.9C125.8,60.46,124.9,59.7,124.9,58.53Z" />
                    <path d="M131,57.55a4.83,4.83,0,0,1,.08-.82,4.12,4.12,0,0,1,4.14-3.35,2.8,2.8,0,0,1,3,2.94,4.59,4.59,0,0,1-.07.81A4.14,4.14,0,0,1,134,60.49,2.81,2.81,0,0,1,131,57.55Zm5.31-.63a2.38,2.38,0,0,0,0-.45A1.32,1.32,0,0,0,135,55.05a2.22,2.22,0,0,0-2.11,1.89,2.49,2.49,0,0,0-.05.46,1.31,1.31,0,0,0,1.37,1.41A2.23,2.23,0,0,0,136.3,56.92Z" />
                    <path d="M143.67,55.08h-2.45L141,56.4h2.24L142.87,58h-2.22l-.48,2.39h-1.79l1.37-6.86H144Z" />
                    <path d="M149.72,53.5l-.32,1.6h-1.69l-1.06,5.26h-1.79l1-5.26h-1.7l.33-1.6Z" />
                    <path d="M157.77,53.5h1.93l0,.21-3.35,6.65h-1.44l-.44-2-.26-1.24-.71,1.24-1.22,2h-1.46l-.7-6.65,0-.21h1.75l.16,1.67.14,2.39L154,54.67h1.23l.64,2.88L157,55.17Z" />
                    <path d="M163.55,53.5l1.12,6.65,0,.21h-1.79l-.11-1.1h-2.15l-.57,1.1h-1.94l0-.21,3.77-6.65Zm-1.2,2.31-1.07,2.06h1.34Z" />
                    <path d="M169.5,57.89l1.26,2.26,0,.21h-1.84l-1.27-2.45-.49,2.45h-1.78l1.37-6.86h2.42c1.36,0,2.24.73,2.24,1.9a2.94,2.94,0,0,1,0,.51A2.42,2.42,0,0,1,169.5,57.89Zm.06-2.17c0-.37-.22-.61-.68-.61h-.71l-.31,1.58h.69a.91.91,0,0,0,1-.79A1.41,1.41,0,0,0,169.56,55.72Z" />
                    <path d="M177,55h-2.63l-.23,1.11h2.44l-.31,1.5h-2.42l-.24,1.16h2.7L176,60.36h-4.46l1.37-6.86h4.39Z" />
                    <path d="M183.43,53.5a2,2,0,0,1,2.15,2,2.91,2.91,0,0,1-.06.57,2.69,2.69,0,0,1-2.85,2.33h-1l-.38,1.93H179.5l1.37-6.86Zm.35,2.27a.65.65,0,0,0-.73-.68h-.73L182,56.88h.78a1,1,0,0,0,1-.88A1,1,0,0,0,183.78,55.77Z" />
                    <path d="M190.69,53.5h2l0,.21-3.81,6.65h-1.5l-1.17-6.65,0-.21H188l.21,1.47.33,2.69L189.89,55Z" />
                    <path d="M198.25,53.5l-.32,1.6h-1.69l-1.06,5.26h-1.79l1.05-5.26h-1.7l.33-1.6Z" />
                    <path d="M196.79,59.74a1.48,1.48,0,0,1,0-.21,1.08,1.08,0,0,1,1.08-.9.72.72,0,0,1,.8.75,1.21,1.21,0,0,1,0,.19,1.09,1.09,0,0,1-1.08.88C197.08,60.45,196.79,60.17,196.79,59.74Z" />
                    <path d="M201.92,60.36l1.38-6.86h1.79L204,58.73h2.77l-.33,1.63Z" />
                    <path d="M212.73,53.5l-.32,1.6h-1.69l-1.06,5.26h-1.79l1-5.26h-1.7l.32-1.6Z" />
                    <path d="M212.31,60.36l1.37-6.86h2.56a2.79,2.79,0,0,1,3,2.86,3.48,3.48,0,0,1-.08.77A4,4,0,0,1,215,60.36Zm2.13-1.64h.82A2.12,2.12,0,0,0,217.35,57a3.13,3.13,0,0,0,0-.42A1.33,1.33,0,0,0,216,55.13h-.85Z" />
                    <path d="M218.79,59.74a1.49,1.49,0,0,1,0-.21,1.08,1.08,0,0,1,1.08-.9.72.72,0,0,1,.8.75,1.23,1.23,0,0,1,0,.19,1.08,1.08,0,0,1-1.07.88C219.09,60.45,218.79,60.17,218.79,59.74Z" />
                    <path d="M34.16,9.54,33,13.11c-2.61-2.78-6.42-4.36-11.15-4.36-10.66,0-19.06,7.39-21.32,19A22.54,22.54,0,0,0,0,32.42c0,9.41,6.12,15.94,15.53,15.94a19.88,19.88,0,0,0,12-3.94v3.15h9.81l7.47-38Zm-5.52,19c-1,5.23-5.21,9-9.87,9-4,0-6.35-2.44-6.35-6.53a10.64,10.64,0,0,1,.27-2.51c1.06-5.24,5.1-8.75,9.75-8.75,3.94,0,6.41,2.57,6.41,6.58A11.86,11.86,0,0,1,28.64,28.49Z" />
                    <path d="M83.78,24.53c0-9.18-6.36-15.86-16.86-15.86C55.54,8.67,46.45,16.36,44.19,28a24.82,24.82,0,0,0-.43,4.52c0,9.54,6.49,15.93,16.86,15.93A22.1,22.1,0,0,0,81,35.44H68.33a8.93,8.93,0,0,1-6.44,2.66c-3.66,0-6-2.08-6.21-5.89H82.44A23.68,23.68,0,0,0,83.78,24.53Zm-26.62.36c1.78-3.72,4.81-6,8.54-6s5.87,2.53,6.22,6Z" />
                    <path d="M116.53,9.54l-1.2,3.51a14.46,14.46,0,0,0-10.87-4.3c-10.24,0-18.35,6.66-20.39,17.36a20.62,20.62,0,0,0-.43,4.09c0,8.69,6,14.64,15,14.64a19.31,19.31,0,0,0,9.39-2.3v.08c-1,5.24-4.31,7.32-8.81,7.32-3.95,0-5.79-1.22-5.71-3.59H81.81a8.58,8.58,0,0,0-.08,1.43c0,7.76,6.49,12.71,16.16,12.71,6.38,0,11.59-1.55,15.43-4.83a16.76,16.76,0,0,0,2.34-2.42,23.22,23.22,0,0,0,4.54-10.33l.65-3.32,1.05-5.33,4.86-24.72ZM111.1,27A9.14,9.14,0,0,1,102,34.51a5.6,5.6,0,0,1-6-5.89,8.81,8.81,0,0,1,9.19-9.11c3.73,0,6.07,2.37,6.07,5.89C111.24,25.9,111.16,26.4,111.1,27Z" />
                    <path d="M165.74,21.3c.21-2.3-1.28-3.38-4-3.38-.24,0-.47,0-.68,0-2.32.16-3.5,1.12-3.5,2.65s1.43,2.28,5.3,3.18c.25.08.52.14.79.2,8.19,1.85,11.78,5.31,11.78,10.68a13.66,13.66,0,0,1-.29,2.8A12.37,12.37,0,0,1,174,40.88c-2.72,5.33-9.08,7.55-16.47,7.55-9,0-15.39-4-15.39-11a11.84,11.84,0,0,1,.21-2.15l12.07.06c-.29,2.3,1.2,3.75,4.17,3.75,2.82,0,4.65-1,4.65-3,0-1.44-1.41-2.24-6-3.3-8.11-1.87-11.72-5.17-11.72-10.56a13.75,13.75,0,0,1,.28-2.8c1.26-6.49,7.05-9.85,14-10.55a27.2,27.2,0,0,1,2.74-.13c8.34,0,14.83,3.8,14.83,10.62a8.38,8.38,0,0,1-.15,1.93Z" />
                    <path d="M143.16,15.53l-6.3,32H124.79l3.85-19.51A62,62,0,0,1,143.16,15.53Z" />
                </g>
            </svg>
        )
    }


    //  Call Support Details
    const ContactInformation = () => {
        return (
            <>
                <div className='icon-container'>
                    <div className='icon-wrapper' title='Call Support'>
                        <FontAwesomeIcon icon={faHeadset} className='icon' />
                    </div>
                    <div className='icon-wrapper' title='WhatsApp Support'>
                        <FontAwesomeIcon icon={faWhatsapp} className='icon' />
                    </div>
                    <span className='title'>Support via call and WhatsApp</span>
                </div>
                <div className='as-contact-details'>
                    <span className='value'>
                        <font>980-234-2708</font>,&nbsp;&nbsp;
                        <font>980-234-2709</font>
                    </span>
                </div>
            </>
        )
    }


    //  Aegis Logo Overlay Cover Container
    const OverlayContent = (class_name) => {
        return (
            <div className={`as-overlay-display ${class_name}`}>
                <div className='as-aegis-logo'>
                    <AegisSoftwareLogo />
                </div>
                <div className='as-contact-information'>
                    <ContactInformation />
                </div>
            </div>
        )
    }

    console.log(licenseKey)

    return (
        <section className='as-login-section'>
            {modal}
            <div className={`as-login-container ${loginActiveClass}`} id="as-login-container">
                <div className="as-form-container as-reset-password-form-container">
                    <form className='as-login-form' onSubmit={ProceedLogin}>
                        <div className='as-login-form-control as-form-logo-top'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <AegisSoftwareLogo />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='as-login-form-control'>
                            <span className='as-login-text'>Renew License</span>
                            <span className='as-login-info'>Please enter the License Key provided by Aegis Administration.</span>
                            {/* <span className='as-login-info'>Please check your email address before resetting your password. Your new password will be sent to your email address.</span> */}
                        </div>
                        {/* <div className='as-login-form-control'>
                            <input
                                type="text"
                                name="username"
                                placeholder="Username"
                                id="reset_username"
                                autoComplete="off"
                                className='as-input-username'
                                ref={usernameRef}
                            />
                        </div>
                        <div className='as-login-form-control'>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email Address"
                                id="reset_password"
                                autoComplete="off"
                                className='as-input-email'
                            />
                        </div> */}
                        <div className='as-login-form-control'>
                            <input
                                type="text"
                                id="license_key"
                                name="license_key"
                                autoComplete="off"
                                placeholder="Enter License Key..."
                                onChange={(e) => setLicenseKey(e.target.value)} value={licenseKey}
                                ref={licenseKeyRef}
                            />
                        </div>
                        <div className='as-login-form-control'>
                            <span
                                id="goToLogin"
                                className="as-forgot-password"
                                onClick = {() => {
                                    setLoginActiveClass('')
                                    if(usernameRef.current != null) usernameRef.current.focus()
                                }}
                            >
                                Go back to Login
                                </span>
                        </div>
                        <div className='as-login-form-control'>
                            {
                                showSpinner ?
                                    <button type='button' className='as-logging-in-btn' disabled>Please Wait...</button> :
                                    // <button type='button' className='as-login-btn'>Reset Password</button>
                                    <button type='button' className='as-login-btn' onClick={() => LicenseRenew()}>Save License Key</button>
                            }
                        </div>
                        <div className='as-login-form-control'>
                            <div className='as-login-options'>
                                {/* <a href={void (0)}>FAQ</a>
                                <a href={void (0)}>Features</a>
                                <a href={void (0)}>Support</a> */}
                            </div>
                        </div>
                        <div className='as-login-form-control'>
                            <div className='as-license-info'>
                                <span>Licensed to: <span className='as-aegis-text' id="viewLicenseTo1">{propertyName}</span></span>
                            </div>
                            <div className='as-copyright-info'>
                                <span>Copyright &copy; {new Date().getFullYear()}. <span className='as-aegis-text dark'>AEGIS SOFTWARE PVT. LTD.</span></span>
                            </div>
                            <div className='as-contact-information small-screen'>
                                <ContactInformation />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="as-form-container as-login-form-container">
                    <form className='as-login-form' onSubmit={ProceedLogin}>
                        <div className='as-login-form-control as-form-logo-top'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <AegisSoftwareLogo />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='as-login-form-control'>
                            <span className='as-login-text'>Login</span>
                            <span className='as-login-info'>Please provide your valid login details to get access.</span>
                        </div>
                        <div className='as-login-form-control'>
                            <input
                                type="text"
                                name="username"
                                placeholder={(usernameError == '' ? "Username" : usernameError)}
                                id="login_username"
                                autoComplete="off"
                                className={'as-input-username' + (usernameError == '' ? '' : ' as-login-error-message')}
                                onChange={(e) => setUsername(e.target.value)} value={username}
                                ref={usernameRef}
                            />
                        </div>
                        <div className='as-login-form-control'>
                            <input
                                ref={passwordRef}
                                type="password"
                                name="password"
                                placeholder={(passwordError == '' ? "Password" : passwordError)}
                                id="login_password"
                                autoComplete="off"
                                className={'as-input-password' + (passwordError == '' ? '' : ' as-login-error-message')}
                                onChange={(e) => setPassword(e.target.value)} value={password}
                            />
                        </div>
                        {isOTPEnabled?
                            <div className='as-login-form-control'>
                                <input
                                    ref={otpRef}
                                    type="text"
                                    name="otp"
                                    placeholder={(otpError == '' ? "OTP" : otpError)}
                                    id="login_otp"
                                    autoComplete="off"
                                    className={'as-input-otp ' + (otpError == '' ? '' : ' as-login-error-message')}
                                    onChange={(e) => setOTP(e.target.value)} value={otp}
                                />
                            </div>
                        :<></>}
                        <div className='as-login-form-control'>
                            <span
                                id="forgotPass"
                                className="as-forgot-password"
                                onClick = {() => {
                                    setLoginActiveClass('as-active-form')
                                    if(licenseKeyRef.current != null) licenseKeyRef.current.focus()
                                }}
                            >
                                Renew License
                            </span>
                        </div>
                        <div className='as-login-form-control'>
                            {
                                showSpinner ?
                                    <button type='button' className='as-logging-in-btn'>Logging In...</button> :
                                    <button type='submit' className='as-login-btn'>Login</button>
                            }
                        </div>
                        <div className='as-login-form-control'>
                            {/* <div className='as-login-options'>
                                <a href={void (0)}>FAQ</a>
                                <a href={void (0)}>Features</a>
                                <a href={void (0)}>Support</a>
                            </div> */}
                        </div>
                        <div className='as-login-form-control'>
                            <div className='as-license-info'>
                                <span>Licensed to: <span className='as-aegis-text' id="viewLicenseTo2">{propertyName}</span></span>
                            </div>
                            <div className='as-copyright-info'>
                                <span>Copyright &copy; {new Date().getFullYear()}. <span className='as-aegis-text dark'>AEGIS SOFTWARE PVT. LTD.</span></span>
                            </div>
                            <div className='as-contact-information small-screen'>
                                <ContactInformation />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="as-aegis-cover-container">
                    <div className="as-cover-overlay">
                        {OverlayContent('as-cover-left')}
                        {OverlayContent('as-cover-right')}
                    </div>

                </div>
            </div>
            <div
                style={{
                    position:"absolute",
                    bottom:5,
                    right:10
                }}
            >{patchVersion!=""?"Patch Version: "+patchVersion:""}</div>
        </section>
    );

}

export default Login;