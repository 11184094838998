import {ShowSnackbar, ResponseSnackbar} from 'layout/snackbar/Snackbar';
import {ShowLoginForm} from 'modules/login/Login.js';
import { AppConfig, getCookie, setCookie } from './Library';

/**
 * Concat this function to URL with ?. It will append parameter in the url.
 * @obj json object as get parameter.
 */
export const UrlParams = (obj) =>{
    return Object
        .keys(obj)
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
        .join('&');
}

/**
 * {
 *     TYPE:'GET',
 *     URL:'',
 *     CALLBACK:function(res){},
 *     COMPLETE:function(){},
 *     DATA:{}
 * }
 * @TYPE  GET/POST
 * @URL URL to send request on.
 * @CALLBACK Callback function as response. It get response of true if it is failed.
 * @DATA Request data in json format. It also accepts FormData.
 */
export function ApiRequest({TYPE='GET',URL='',CALLBACK=function(res){},COMPLETE=function(){},DATA={}}){
    
    // 1. Create a new XMLHttpRequest object
    let xhr = new XMLHttpRequest();
    
    // 2. Configure it: GET-request for the URL /article/.../load
    //process.env.REACT_APP_API_URL
    xhr.open(TYPE, AppConfig("api_url")+(URL[0]=='/'?URL:'/'+URL));//GET,'/example/url'

    xhr.responseType = 'json'

    xhr.setRequestHeader('Authorization', 'Bearer '+localStorage.token);

    // 3. Send the request over the network
    if(DATA instanceof FormData)//If form data
        xhr.send((DATA));
    else{//If json data
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(DATA));
    }
        

    // 4. This will be called after the response is received
    xhr.onload = function() {
        
        //if (xhr.status != 200) { // analyze HTTP status of the response
            //alert(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
        //} else { // show the result
            //alert(`Done, got ${xhr.response.length} bytes`); // response is the server
            try{
                if(processResponse(xhr.response))
                    CALLBACK(xhr.response,xhr.responseURL);
            }catch(error){
                //alert("Error");
                if(error==null || error.message=='response is null')
                ShowSnackbar('info',"Please wait, you will be connected shortly...",5000);
                else
                    alert("Unexpected Error: "+error)
                console.log(error);
            }
        
            COMPLETE();
    };

    // xhr.onprogress = function(event) {
    //     if (event.lengthComputable) {
    //         alert(`Received ${event.loaded} of ${event.total} bytes`);
    //     } else {
    //         alert(`Received ${event.loaded} bytes`); // no Content-Length
    //     }
    // };

    xhr.onerror = function() {
        //alert("Request failed");
        COMPLETE();
    };
    //Call xhr.abort(); to abort request.
    return xhr;
}


function processResponse(response){
    if(getCookie("_ASuL_")==""){
        
        if(document.getElementById("app-login").innerHTML=='')
            ShowLoginForm();
        return false;
    }
    if(response==null){
        ShowSnackbar('info',"Please wait, you will be connected shortly...",5000);
        return false;
    }
    if(response.hasOwnProperty('error_code')){//Valid response.
        try{
            
            if(response.status_code=="200"){
                if(response.data.session_status.continue_request){
                    if(response.data.session_status.token!=null)
                        localStorage.token=response.data.session_status.token;
                }else{
                    localStorage.token="";
                }
                return true;
            }
        }catch(error){
            return true;//if session status extraction is failed.
        }
        
        if(response.status_code=="400"){//Model Validation failure. Currently it only shows one message.
            try{
                ShowSnackbar('error',response.data.model_validation[0].error_messages[0]);
            }catch(error){

            }
        }


        if(response.status_code=="401"){
            if(document.getElementById("app-login").innerHTML=='')
                ShowLoginForm();
            //ShowSnackbar('error','You are logged out. Please login to continue.');
            return false;
        }
    }
    return true;
}

export function DownloadFile(param){
    ShowSnackbar('info','Downloading file. Please wait!');
    var URL="/app/files/download";
    URL=AppConfig("api_url")+(URL[0]=='/'?URL:'/'+URL)+"?"+UrlParams({q:param.q});
        
    // 1. Create a new XMLHttpRequest object
    let xhr = new XMLHttpRequest();
    
    // 2. Configure it: GET-request for the URL /article/.../load
    //process.env.REACT_APP_API_URL
    xhr.open("GET", URL);//GET,'/example/url'

    xhr.responseType = 'blob'

    xhr.setRequestHeader('Authorization', 'Bearer '+localStorage.token);
    
    xhr.send();

    // 4. This will be called after the response is received
    xhr.onload = function() {
        var blob = xhr.response;
        console.log(blob)
        saveBlob(blob, ""+new Date().getTime()+param.file_extension);
    };

    xhr.onerror = function() {
        alert("Request failed");
    };
    //Call xhr.abort(); to abort request.
}

function saveBlob(blob, fileName) {

    if(blob.type=="application/json"){
        var reader = new FileReader();
        reader.onload = function() {
            var res=JSON.parse(reader.result);
            if (processResponse(res)){
                ResponseSnackbar(res);
            }
        }
        reader.readAsText(blob);
        return;
    }

    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    
    a.dispatchEvent(new MouseEvent('click'));
}

export function CreateImageBlob(url){

    return new Promise(resolve => {
       
    var URL=url;
   
        
    // 1. Create a new XMLHttpRequest object
    let xhr = new XMLHttpRequest();
    
    // 2. Configure it: GET-request for the URL /article/.../load
    //process.env.REACT_APP_API_URL
    xhr.open("GET", URL);//GET,'/example/url'

    xhr.responseType = 'blob'

    xhr.setRequestHeader('Authorization', 'Bearer '+localStorage.token);
    
    xhr.send();
    var returnValue = null;
    // 4. This will be called after the response is received
    xhr.onload = function () { 
        var supproted_image_types = ["image/png", "image/jpg","image/jpeg"]             
        var blob = xhr.response;
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        if(supproted_image_types.some(x=> x == blob.type)){ // only make blob of images ignore other file types
            returnValue = imageUrl;
            resolve(returnValue); 
        }else {
            resolve(null)
        }     
        // saveBlob(blob, ""+new Date().getTime()+param.file_extension);       
    };

    xhr.onerror = function() {
        alert("Request failed");
        resolve(returnValue); 
    };
     
    //return returnValue;        
             
      });

    
}

export function PreviewFile(param){
    ShowSnackbar('info','Opening File. Please wait!');
    var URL="/app/files/download";
    URL=AppConfig("api_url")+(URL[0]=='/'?URL:'/'+URL)+"?"+UrlParams({q:param.q});
        
    // 1. Create a new XMLHttpRequest object
    let xhr = new XMLHttpRequest();
    
    // 2. Configure it: GET-request for the URL /article/.../load
    //process.env.REACT_APP_API_URL
    xhr.open("GET", URL);//GET,'/example/url'

    xhr.responseType = 'blob'

    xhr.setRequestHeader('Authorization', 'Bearer '+localStorage.token);
    
    xhr.send();

    // 4. This will be called after the response is received
    xhr.onload = function() {
        var blob = xhr.response;
        console.log(blob)
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        var new_window = window.open(imageUrl, '_blank', `width=600,height=900,left=${window.innerWidth * 30/100}`);
        new_window.focus();
        new_window.document.title = "File Preview"
        
    };

    xhr.onerror = function() {
        alert("Request failed");
    };
    //Call xhr.abort(); to abort request.
}