import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './styles/scss/minified.scss';
import './App.css';
import SidebarLatest from 'layout/sidebar/SidebarLatest';
import Topbar from 'layout/topbar/Topbar';
import Unauthorized from './Unauthorized.js';
import AppRoute from './AppRoute.js';
import {BrowserRouter as Router} from "react-router-dom";
import { ApiRequest, UrlParams } from 'library/APICall';
import { GetAcDate, IsModalShowing, SetAcDate, SetDateFormat, WindowFocused } from 'library/Library';
import Actionbar from 'layout/actionbar/Actionbar';
import DayEndReportContextProvider from 'providers/DayEndReportContextProvider';
import { ShowSnackbar } from 'layout/snackbar/Snackbar';
import { AlertMessage } from 'layout/alert/Alert';
import { LogoutUserAlert } from 'modules/login/Login';
import InvalidTZ from 'InvalidTZ';

export const AppContext=React.createContext();

function App() {

  const [isTouchScreen, setIsTouchScreen] = useState(false)   //  State for touch screen

  const [isValidTZ,setValidTZ]=useState(new Date().getTimezoneOffset() == -345);

  const [appState,setAppState]=useState({
    ac_date:null,
    property_name:'',
    multi_property_code:'',
    is_unauthorized:false,
    fiscal_year:[],
    product:'',
    product_version:"1.0",
    user:{
      force_password_change:false,
      default_module_id:1
    },
    module_lock_message:null,
    license:null
  });

  document.title="Aegis"+appState.product;

  useEffect(()=>{
    if(appState.ac_date!=null)
      SetAcDate(appState.ac_date);

    if(appState.module_lock_message!="" && appState.module_lock_message!=null){
      ShowSnackbar("error",appState.module_lock_message,10000)
    }
  },[appState]);
  

  const LoadBaseData=()=>{
    if(IsModalShowing()) return;
    if(localStorage.token==undefined) return;
    setAppState((state)=>{
      return {...state,ac_date:null};//Setting ac_date to null so that it can show loader
    });
    var params = UrlParams({
      user_name: localStorage.user_name == undefined ? '' : localStorage.user_name,
      token: localStorage.token == undefined ? '' : localStorage.token,
      unauth: localStorage.token==undefined ? true : false,
      current_url : window.location.pathname
  });
    ApiRequest({ TYPE:'GET', URL:'/Application/appbase?'+params, CALLBACK:function(response){
      setTimeout(()=>{
        if(document.getElementById("viewLicenseTo1")!=null){
          document.getElementById("viewLicenseTo1").innerText=response.data.property_name
        }
        if(document.getElementById("viewLicenseTo2")!=null){
          document.getElementById("viewLicenseTo2").innerText=response.data.property_name
        }
      },1000)
      SetDateFormat(response.data.date_format);

      if(response.data.license.is_active==false){
        setTimeout(()=>{
          LogoutUserAlert(false)
        },5000)
        AlertMessage({type:'error',title:'License Expired',message:'Please use a valid license key to use the system.',btnTypeId:1,btnConfirmText:"Okay",
          confirmCallBack:()=>LogoutUserAlert(false)
        })
      }else{
        var days=parseInt(response.data.license.days); 
        if(days<=7 && days>0){
          var message='System will be down in '+(parseInt(days))+" days. Please renew your license."
          if(days==1)
            message="System will be down tomorrow. Please renew your license."
          if(localStorage.license_message_shown!='true'){
            AlertMessage({type:'error',title:'License Expiring',message:message,btnTypeId:1,btnConfirmText:"Okay"})
            localStorage.license_message_shown='true';
          }
        }
        else if(days<=0){
          setTimeout(()=>{
            LogoutUserAlert(false)
          },5000)
          AlertMessage({type:'error',title:'License Has Expired',message:'Please use a valid license key to use the system.',btnTypeId:1,btnConfirmText:"Okay",})
        }
      }
      
      setAppState((state)=>{
        return {
                ...state,
                ac_date:response.data.ac_date,
                property_name:response.data.property_name,
                multi_property_code:response.data.multi_property_code,
                property_information:response.data.property_information,
                fiscal_year:response.data.fiscal_year,
                product:response.data.product,
                system_files_access_code:response.data.system_files_access_code,
                user:{
                  ...state.user,
                  force_password_change:response.data.force_password_change,
                  default_module_id:response.data.default_module_id
                },
                module_lock_message:response.data.module_lock_message,
                license:response.data.license,
                patch_version:response.data.PatchVersion,
                bill_print_config:response.data.bill_print_config
              };
      });
    }, COMPLETE:function(){
      
    }, DATA:{} })
  }

  useEffect(()=>{
    LoadBaseData();

    //  Get query for touch screen
    var path_name = window.location.pathname
    if(path_name.substring(0,12) == '/aegistouch/')
      setIsTouchScreen(true)
    else
      setIsTouchScreen(false)

  },[]);

  useEffect(()=>{
    var r1=WindowFocused(()=>LoadBaseData());
    return ()=>{r1()}
  })

  return (
    (isValidTZ?
      <DayEndReportContextProvider>
        <>
        
          <AppContext.Provider value={{appState:appState,reloadState:LoadBaseData,setAppState:setAppState, setIsTouchScreen: setIsTouchScreen}} >
            <Router>
              {
                isTouchScreen
                ?
                  <></>
                :
                  <SidebarLatest />
              }
              {
                appState.is_unauthorized
                ?
                  <Unauthorized/>
                :
                  <AppRoute />
              }
            </Router>
          </AppContext.Provider>

          <div id="overlay"></div>

        </>
      </DayEndReportContextProvider>
      :<><InvalidTZ/></>)
  );

}

export default App;
