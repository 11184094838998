import React,{useEffect,useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube,faCaretRight,faCubes, faWindowMinimize } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import {TitleCase, SetAcDate} from 'library/Library';
import {ApiRequest} from 'library/APICall';
import {AlertMessage} from 'layout/alert/Alert';
import {ShowSnackbar} from 'layout/snackbar/Snackbar';
import Loader from 'layout/loader/Loader';

function MenuList(props) {
  const [menuList, setMenuList] = useState([]);
  const [loader,setLoader]=useState(false);

  useEffect(() => {
    GetAllMenu();
  }, [props.reloadMenu]);

  //Component did update.
  useEffect(()=>{
      if(menuList.length>0)
        window.setMenuActive();//Active the clicked menu.
  })

  const GetAllMenu = () =>{
      setLoader(true);
      ApiRequest({ TYPE:'GET', URL:'/Application/menu', CALLBACK:function(response){
        setMenuList(response.data.menu);
      }, COMPLETE:function(){
          setLoader(false);
      }, DATA:{} })
  }
  
    const listItems = menuList.map((level_one) =>
            <li className="menuGroup" key={level_one.id}>
                <a className="listLink level-one-menu-link"><FontAwesomeIcon icon={faCubes} /> {TitleCase(level_one.name)} <FontAwesomeIcon icon={faCaretRight} /></a>
                <ul className="secondLevelUL" id="secondLevel" style={{display:"none"}}>
                    {
                        level_one.level_two.map((level_two)=>{
                            return(
                                <li className="subMenuGroup" key={level_two.id}>
                                    <a className="listLink secondaryListItem level-two-menu-link" >  <FontAwesomeIcon icon={faCube} />{TitleCase(level_two.name)}<FontAwesomeIcon icon={faCaretRight} /></a>
                                    <ul className="thirdLevelUL" id="thirdLevel" style={{display:"none"}}>
                                    {
                                        level_two.level_three.map((level_three)=>{
                                            return(
                                                <li className="thirdSubMenuGroup" key={level_three.id}>
                                                    <Link 
                                                        to={{ pathname: level_three.link, state: { menu_id:  level_three.id} }}
                                                        className="listLink secondaryListItem level-three-menu-link"
                                                        title={level_three.description}>
                                                        {TitleCase(level_three.name)}
                                                    </Link>
                                                </li>
                                            );
                                        })
                                    }
                                    </ul>
                                </li>
                            );
                        })
                    }
                    
                </ul>
            </li>
    );

    return (
        <>
            {(loader?<Loader height="100%" />:listItems)}
        </>
    );
}


export default MenuList;