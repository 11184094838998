import React, { useState, useEffect,useRef, useContext } from 'react';
import './Sidebar.css';
import '../../styles/scss/sidebar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faHome, faSync, faGlassCheers, faTachometerAlt, faIdBadge, faBusinessTime, faCubes, faDollarSign, faHandHoldingUsd, faCog, faBeer, faCogs, faCube, faHotel, faHeadSideCoughSlash } from '@fortawesome/free-solid-svg-icons';
import MenuList from './MenuList';
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import Dropdown from 'layout/dropdown/Dropdown';
import MegaMenu from './MegaMenu';
import { ApiRequest } from 'library/APICall';
import useModalStack, { PopUp } from 'hooks/useModalStack';
import { readQueryString, AddEvent_CTRL,AddEvent_ESC } from 'library/Library';
import { AppContext } from 'App';

function Sidebar(props) {

    var app_context=useContext(AppContext);

    //It holds module LINK for short cut key.
    const ref_module = useRef([]);

    const [reloadMenu, setReloadMenu] = useState(0);

    const [menuList, setMenuList] = useState([]);

    const [openModelId, setOpenModelId] = useState(-1);

    const [modal,push,pop]=useModalStack();

    const thePOP=()=>{
        setOpenModelId(-1);
        pop();
    }

    const [activeModuleId,setActiveModuleId]=useState(1);


    const openMenu=(menuIndex)=>{
        if(menuIndex<ref_module.current.length){
            ref_module.current[menuIndex].click();
            ref_module.current[menuIndex].focus();
            setOpenModelId(menuIndex);
        }
    }

    useEffect(()=>{
        var r1=AddEvent_CTRL("1",()=>openMenu(0));
        var r2=AddEvent_CTRL("2",()=>openMenu(1));
        var r3=AddEvent_CTRL("3",()=>openMenu(2));
        var r4=AddEvent_CTRL("4",()=>openMenu(3));
        var r5=AddEvent_CTRL("5",()=>openMenu(4));

        //Closing Menu On ESC
        var close=AddEvent_ESC(thePOP);
        return ()=>{
            r1();
            r2();
            r3();
            r4();
            r5();

            close();
        }
    });

    useEffect(() => {
        ApiRequest({
            TYPE: 'GET', URL: '/Application/menu', CALLBACK: function (res) {
                setMenuList(res.data.menu);
            }, COMPLETE: function () { }, DATA: {}
        })
    }, []);

    useEffect(()=>{
        if(menuList.length>0){
            
            if(window.location.pathname=="/"){
                debugger
                setTimeout(()=>{
                    debugger
                    var module_id=1;
                    if(app_context.appState.user.default_module_id == null || app_context.appState.user.default_module_id == 0 ){
                        module_id=1;
                    }else{
                        module_id=app_context.appState.user.default_module_id;
                    }
                    var index_of_menu=menuList.findIndex(f=>f.id==module_id);
                    
                    if(index_of_menu!=-1) {
                        openMenu(index_of_menu)
                    }else{
                        openMenu(0)
                    }
                },250);
            }
        }
    },[menuList.length])

    
    useEffect(()=>{
        //When menu list is loaded call this. It will catch the url and update the highlighted module.
        var url=window.location.href;
        if(url.includes("/app/")) setActiveModuleId(1);
        else if(url.includes("/fo/")) setActiveModuleId(2);
        else if(url.includes("/pos/")) setActiveModuleId(3);
        else if(url.includes("/inv/")) setActiveModuleId(4);
        else if(url.includes("/ac/")) setActiveModuleId(5);
        else if(url.includes("/cst/")) setActiveModuleId(6);
        else if(url.includes("/hk/")) setActiveModuleId(7);
        else if(url.includes("/be/")) setActiveModuleId(9);
    },[menuList]);

    if (menuList.length == 0)
        return (<div className='as-sidebar'></div>)

    //Default Icon for module
    var moduleIcon = <FontAwesomeIcon icon={faCube}/>


    return (

        <>
            {modal}
            <div className='as-sidebar'>
                <ul className="as-modules">
                    <li className="as-modules-lists as-app-icon-wrapper" title={`AEGIS${app_context.appState.product}`}>
                        <svg className='as-software-logo' viewBox="0 0 220.69 60.49">
                            <g>
                                <path className='highlight' d="M208,6.62a48.35,48.35,0,0,1-.41,6.28c-.07.62-.17,1.26-.29,1.87a48.29,48.29,0,0,1-23.44,33.66,49.24,49.24,0,0,1-7.69-5.52A14,14,0,0,0,178,38a16.31,16.31,0,0,0,.35-3.39,11.77,11.77,0,0,0-5.91-10.45h7.1l.5-2.28a10.8,10.8,0,0,0,.22-2.55c0-6.33-4.37-11-11.32-12.77a25.47,25.47,0,0,0-6.37-.75h0q-.93,0-1.8.06A48.22,48.22,0,0,1,208,6.47C208,6.53,208,6.57,208,6.62Z" />
                                <path className='highlight' d="M144.34,9.54l-.72,3.69A62.6,62.6,0,0,0,129.29,24.7l3-15.16Z" />
                                <path d="M34.16,9.54,33,13.11c-2.61-2.78-6.42-4.36-11.15-4.36-10.66,0-19.06,7.39-21.32,19A22.54,22.54,0,0,0,0,32.42c0,9.41,6.12,15.94,15.53,15.94a19.88,19.88,0,0,0,12-3.94v3.15h9.81l7.47-38Zm-5.52,19c-1,5.23-5.21,9-9.87,9-4,0-6.35-2.44-6.35-6.53a10.64,10.64,0,0,1,.27-2.51c1.06-5.24,5.1-8.75,9.75-8.75,3.94,0,6.41,2.57,6.41,6.58A11.86,11.86,0,0,1,28.64,28.49Z" />
                                <path d="M83.78,24.53c0-9.18-6.36-15.86-16.86-15.86C55.54,8.67,46.45,16.36,44.19,28a24.82,24.82,0,0,0-.43,4.52c0,9.54,6.49,15.93,16.86,15.93A22.1,22.1,0,0,0,81,35.44H68.33a8.93,8.93,0,0,1-6.44,2.66c-3.66,0-6-2.08-6.21-5.89H82.44A23.68,23.68,0,0,0,83.78,24.53Zm-26.62.36c1.78-3.72,4.81-6,8.54-6s5.87,2.53,6.22,6Z" />
                                <path d="M116.53,9.54l-1.2,3.51a14.46,14.46,0,0,0-10.87-4.3c-10.24,0-18.35,6.66-20.39,17.36a20.62,20.62,0,0,0-.43,4.09c0,8.69,6,14.64,15,14.64a19.31,19.31,0,0,0,9.39-2.3v.08c-1,5.24-4.31,7.32-8.81,7.32-3.95,0-5.79-1.22-5.71-3.59H81.81a8.58,8.58,0,0,0-.08,1.43c0,7.76,6.49,12.71,16.16,12.71,6.38,0,11.59-1.55,15.43-4.83a16.76,16.76,0,0,0,2.34-2.42,23.22,23.22,0,0,0,4.54-10.33l.65-3.32,1.05-5.33,4.86-24.72ZM111.1,27A9.14,9.14,0,0,1,102,34.51a5.6,5.6,0,0,1-6-5.89,8.81,8.81,0,0,1,9.19-9.11c3.73,0,6.07,2.37,6.07,5.89C111.24,25.9,111.16,26.4,111.1,27Z" />
                                <path d="M165.74,21.3c.21-2.3-1.28-3.38-4-3.38-.24,0-.47,0-.68,0-2.32.16-3.5,1.12-3.5,2.65s1.43,2.28,5.3,3.18c.25.08.52.14.79.2,8.19,1.85,11.78,5.31,11.78,10.68a13.66,13.66,0,0,1-.29,2.8A12.37,12.37,0,0,1,174,40.88c-2.72,5.33-9.08,7.55-16.47,7.55-9,0-15.39-4-15.39-11a11.84,11.84,0,0,1,.21-2.15l12.07.06c-.29,2.3,1.2,3.75,4.17,3.75,2.82,0,4.65-1,4.65-3,0-1.44-1.41-2.24-6-3.3-8.11-1.87-11.72-5.17-11.72-10.56a13.75,13.75,0,0,1,.28-2.8c1.26-6.49,7.05-9.85,14-10.55a27.2,27.2,0,0,1,2.74-.13c8.34,0,14.83,3.8,14.83,10.62a8.38,8.38,0,0,1-.15,1.93Z" />
                                <path d="M143.16,15.53l-6.3,32H124.79l3.85-19.51A62,62,0,0,1,143.16,15.53Z" />
                            </g>
                        </svg>
                    </li>
                    {menuList.map((x,index) => {
                        var moduleId = x.id;

                        // Application Icon
                        if(moduleId == 1) {
                            moduleIcon = <svg className='as-local-svg' x="0px" y="0px" viewBox="0 0 150 150">
                                            <path
                                                id="Window_x5F_Frame"
                                                d="M125,2.27H24C11.94,2.27,2.16,12.04,2.15,24.1c0,0.01,0,0.03,0,0.04v86.93
                                                c0.01,12.06,9.79,21.84,21.85,21.83h36.7c-2.91-4.38-5.14-9.18-6.6-14.23H24.94c-4.43-0.03-8-3.63-8-8.06V39.55c0-4.42,3.58-8,8-8
                                                c0.02,0,0.03,0,0.05,0h99.73c4.42,0,8,3.58,8,8v20.82c5.53,3.61,10.33,8.24,14.14,13.64V24.14c0.01-12.07-9.76-21.86-21.83-21.87
                                                C125.02,2.27,125.01,2.27,125,2.27z M24.8,24.01c-3.71,0.01-6.71-2.99-6.72-6.7s2.99-6.71,6.7-6.72c3.71-0.01,6.71,2.99,6.72,6.7
                                                c0,0,0,0.01,0,0.01c0.01,3.69-2.98,6.7-6.67,6.71C24.82,24.01,24.81,24.01,24.8,24.01z M43.01,24.01c-3.71,0.01-6.71-2.99-6.72-6.7
                                                c-0.01-3.71,2.99-6.71,6.7-6.72c3.71-0.01,6.71,2.99,6.72,6.7c0,0,0,0.01,0,0.01c0.01,3.7-2.99,6.7-6.69,6.71
                                                C43.01,24.01,43.01,24.01,43.01,24.01L43.01,24.01z"
                                            />
                                            <path
                                                id="Cog_x5F_Outer_x5F_Shape"
                                                d="M146.86,99.08c-0.16-0.06-0.32-0.08-0.49-0.08l-8.87-1.24
                                                c-0.41-2.22-1.05-4.39-1.92-6.48c-0.14-0.37-0.3-0.72-0.47-1.07c-0.68-1.53-1.48-3.01-2.39-4.41c-0.32-0.51-0.64-1-1-1.48l1-1.32
                                                l4.36-5.79c0.53-0.6,0.49-1.51-0.08-2.07l-4.32-4.18l-0.56-0.54c-0.58-0.59-1.52-0.59-2.11-0.01c0,0-0.01,0.01-0.01,0.01l-7,5.52
                                                c-3.96-2.63-8.42-4.39-13.1-5.19l-1.21-8.78c-0.01-0.83-0.68-1.5-1.51-1.49c0,0,0,0,0,0h-6.79c-0.82,0-1.49,0.67-1.5,1.49l-1.1,8.88
                                                c-2.41,0.42-4.76,1.14-7,2.13c-2.19,0.98-4.28,2.18-6.22,3.59l-7.21-5.29c-0.58-0.59-1.53-0.59-2.12-0.01c0,0-0.01,0.01-0.01,0.01
                                                l-4.72,4.86c-0.59,0.59-0.59,1.54,0,2.13l5.59,7l0,0c-2.61,3.75-4.38,8.02-5.2,12.51l-8.87,1.24c-0.82,0-1.49,0.67-1.5,1.49v6.8
                                                c0.01,0.83,0.67,1.49,1.5,1.5l8.82,1.09l0,0c0.42,2.4,1.09,4.74,2,7c0.25,0.61,0.51,1.22,0.79,1.8c0.76,1.58,1.63,3.09,2.62,4.54
                                                l-5.45,7c-0.58,0.59-0.58,1.53,0,2.12l0.57,0.58l4.16,4.29c0.59,0.58,1.54,0.58,2.13,0l5.71-4.33l1.42-1.06
                                                c0.56,0.41,1.07,0.71,1.65,1.06c1.34,0.84,2.75,1.58,4.2,2.21c2.41,1.07,4.95,1.85,7.55,2.3l1.23,8.82
                                                c-0.01,0.82,0.65,1.48,1.47,1.49c0.01,0,0.01,0,0.02,0h6.79c0.82,0.01,1.49-0.66,1.5-1.48c0,0,0-0.01,0-0.01l1.1-8.82l0,0
                                                c4.15-0.74,8.12-2.27,11.69-4.51c0.93-0.58,0.49-0.29,1.4-0.91l0.24,0.16l1,0.75l6.14,4.64c0.59,0.59,1.54,0.59,2.13,0l4.73-4.86
                                                c0.58-0.59,0.58-1.54,0-2.13l-0.84-1.08l-4.67-6c1.34-1.88,2.43-3.92,3.26-6.08c0.14-0.32,0.27-0.63,0.39-1
                                                c0.83-2.11,1.43-4.3,1.81-6.53l8.77-1.09c0.17,0.01,0.34-0.03,0.49-0.1c0.6-0.21,1-0.77,1-1.4v-6.8
                                                C147.84,99.85,147.45,99.29,146.86,99.08z M121,118.67c-8.02,9.26-22.03,10.26-31.29,2.23c-9.26-8.02-10.26-22.03-2.23-31.29
                                                s22.03-10.26,31.29-2.23c4.86,4.21,7.65,10.33,7.65,16.76C126.43,109.48,124.5,114.65,121,118.67z"
                                            />
                                            <circle
                                                id="Cog_x5F_Inner_x5F_Shape"
                                                cx="104.25"
                                                cy="104.11"
                                                r="13.08"
                                            />
                                        </svg>
                        }
                        
                        // Front Office Icon
                        else if (moduleId == 2) {
                            moduleIcon = <svg className='as-local-svg' viewBox="0 0 145.76 105.85">
                                            <path d="M69.24,33.65A10.45,10.45,0,1,1,58.79,23.2h0A10.45,10.45,0,0,1,69.24,33.65Z" transform="translate(-2.62 -22.02)" />
                                            <path d="M140.66,32.46A10.45,10.45,0,1,1,130.2,22h0A10.46,10.46,0,0,1,140.66,32.46Z" transform="translate(-2.62 -22.02)" />
                                            <path d="M148.38,56.28V83.74a8,8,0,0,1-7.49,7.94V122a5.83,5.83,0,0,1-5.83,5.82H122.33a5.83,5.83,0,0,1-5.84-5.82h0V65.71L113,69.17l-8.58,8.59-.59.59a6.13,6.13,0,0,1-8.65,0l-.12-.13a4.31,4.31,0,0,1-.39-.46,6.11,6.11,0,0,1,.51-8L117,47.87a6.06,6.06,0,0,1,4.68-1.78,10.81,10.81,0,0,1,1.61-.13h14.74a10.32,10.32,0,0,1,10.31,10.31Z" transform="translate(-2.62 -22.02)" />
                                            <rect x="78.58" y="74.98" width="21.39" height="30.83" />
                                            <path d="M77.7,97v30.87H76.59a5.11,5.11,0,0,1-5.12-5.1h0v-20.6A5.12,5.12,0,0,1,76.59,97h0Z" transform="translate(-2.62 -22.02)" />
                                            <path d="M99.74,93.57v3h-3v-.85a2.41,2.41,0,0,0-2.41-2.41H89.72a2.43,2.43,0,0,0-2.43,2.41v.85H84.18v-3a2.43,2.43,0,0,1,2.43-2.42H97.33a2.41,2.41,0,0,1,2.41,2.41Z" transform="translate(-2.62 -22.02)" />
                                            <path d="M76.49,56.28V69.21a8.47,8.47,0,0,0-4.25,7.37v1.18H41.14V72.1h6a1.22,1.22,0,0,0,1.23-1.21v-19a1.24,1.24,0,0,0-1.23-1.23H42.86a10.29,10.29,0,0,1,7.93-4.58l6.13,10.62.9-3.94a1.08,1.08,0,0,1,.34-.57,1.77,1.77,0,0,1-1-.8l-1-1.72a1.85,1.85,0,0,1,.67-2.53,1.9,1.9,0,0,1,1-.25h2a1.82,1.82,0,0,1,1.84,1.82,1.72,1.72,0,0,1-.25.94l-1,1.72a1.74,1.74,0,0,1-1,.81,1.17,1.17,0,0,1,.34.56l.88,3.94L66.76,46A10.3,10.3,0,0,1,76.49,56.28Z" transform="translate(-2.62 -22.02)" />
                                            <path d="M89.7,77.1h-17a8.49,8.49,0,0,1,2.49-6l.27-.25a8.49,8.49,0,0,1,14.23,6.26Z" transform="translate(-2.62 -22.02)" />
                                            <path d="M15.81,77.59H12a2,2,0,0,1,.55-1.36l.07-.06a1.86,1.86,0,0,1,.87-.45v.33a.4.4,0,0,0,.34.47.41.41,0,0,0,.47-.34.29.29,0,0,0,0-.13v-.34a1.9,1.9,0,0,1,1,.52A1.93,1.93,0,0,1,15.81,77.59Z" transform="translate(-2.62 -22.02)" />
                                            <path d="M82.34,66.87a1.12,1.12,0,1,1-1.1-1.17,1.14,1.14,0,0,1,1.1,1.16Z" transform="translate(-2.62 -22.02)" />
                                            <path d="M76.59,97H77.7v30.87H76.59a5.11,5.11,0,0,1-5.12-5.1h0v-20.6A5.12,5.12,0,0,1,76.55,97Z" transform="translate(-2.62 -22.02)" />
                                            <path d="M96.78,96.56v-.85a2.41,2.41,0,0,0-2.41-2.41H89.76a2.43,2.43,0,0,0-2.43,2.41v.85H84.18v-3a2.43,2.43,0,0,1,2.43-2.42H97.33a2.41,2.41,0,0,1,2.41,2.41v3Z" transform="translate(-2.62 -22.02)" />
                                            <rect x="78.58" y="74.98" width="21.39" height="30.83" />
                                            <path d="M9.45,77.76H92.66v2.65H9.87A4.07,4.07,0,0,0,5.8,84.48v43.39H3a.42.42,0,0,1-.42-.42h0V84.59a6.83,6.83,0,0,1,6.83-6.83Z" transform="translate(-2.62 -22.02)" />
                                            <path d="M112.05,102.12v20.6a5.1,5.1,0,0,1-5,5.15h-.9V97h.84a5,5,0,0,1,2.13.47A5.12,5.12,0,0,1,112.05,102.12Z" transform="translate(-2.62 -22.02)" />
                                            <path d="M76.59,97H77.7v30.87H76.59a5.11,5.11,0,0,1-5.12-5.1h0v-20.6A5.12,5.12,0,0,1,76.55,97Z"  transform="translate(-2.62 -22.02)" />
                                            <path d="M87.33,96.56H84.18v-3a2.43,2.43,0,0,1,2.43-2.42H97.33a2.41,2.41,0,0,1,2.41,2.41v3h-3v-.85a2.41,2.41,0,0,0-2.41-2.41H89.72a2.43,2.43,0,0,0-2.43,2.41Z" transform="translate(-2.62 -22.02)" />
                                            <rect x="78.58" y="74.98" width="21.39" height="30.83" />
                                            <path d="M46,51.3H16.93a1.24,1.24,0,0,0-1.22,1.24V70.16a1.22,1.22,0,0,0,1.22,1.22H46a1.22,1.22,0,0,0,1.22-1.22h0V52.54A1.24,1.24,0,0,0,46,51.3ZM31.48,64.55a2.67,2.67,0,1,1,2.66-2.68,2.67,2.67,0,0,1-2.66,2.68Z" transform="translate(-2.62 -22.02)" />
                                            <path d="M34.46,77.1v.48H28.51V77.1a.47.47,0,0,1,.46-.47h.83v-5h3.35v5H34A.48.48,0,0,1,34.46,77.1Z" transform="translate(-2.62 -22.02)" />
                                            <rect x="11.21" y="53.57" width="0.09" height="0.29" rx="0.03" />
                                            <line x1="90.03" y1="58.39" x2="90.03" y2="55.75" />
                                            <line x1="38.51" y1="52.19" x2="38.51" y2="50.08" />
                                        </svg>
                        }

                        // POS Icon
                        else if (moduleId == 3) {
                            moduleIcon = <svg className='as-local-svg' x="0px" y="0px" viewBox="0 0 150 150">
                                            <path id="POS_x5F_Device_x5F_1" d="M131.03,31.05h-9.55c-0.44,0-0.79,0.35-0.79,0.78v59.98c0,0.44,0.35,0.79,0.79,0.79
                                                h9.55c1.33,0,2.42-1.09,2.42-2.42V33.46C133.45,32.12,132.36,31.05,131.03,31.05z M125.19,85.07c-1.29,0-2.4-0.76-2.93-1.84
                                                c-0.22-0.44-0.33-0.92-0.33-1.42c0-0.5,0.11-0.98,0.33-1.41c0.52-1.1,1.64-1.86,2.93-1.86c1.81,0,3.26,1.45,3.26,3.26
                                                C128.45,83.61,127,85.07,125.19,85.07z M125.19,75.48c-1.29,0-2.4-0.74-2.93-1.84c-0.22-0.42-0.33-0.9-0.33-1.42
                                                c0-0.5,0.11-0.97,0.33-1.41c0.52-1.1,1.64-1.86,2.93-1.86c1.81,0,3.26,1.47,3.26,3.26C128.45,74.02,127,75.48,125.19,75.48z"/>
                                            <path id="POS_x5F_Device_x5F_2" d="M144.65,37.93h-5.74c-1.03,0-1.87,0.84-1.87,1.87v44.03c0,1.03,0.84,1.87,1.87,1.87
                                                h5.74c1.94,0,3.49-1.56,3.49-3.49V41.42C148.14,39.5,146.58,37.93,144.65,37.93z M144.02,58.93c0,0.45-0.35,0.8-0.8,0.8h-0.89
                                                c-0.45,0-0.8-0.35-0.8-0.8V44.14c0-0.45,0.35-0.8,0.8-0.8h0.89c0.45,0,0.8,0.35,0.8,0.8V58.93z"/>
                                            <path id="Monitor" d="M131.48,115.44H82.91v-6.61c0-0.93-0.4-1.75-1.04-2.32h34.14c1.32,0,2.53-0.4,3.54-1.09
                                                c1.64-1.12,2.71-3.01,2.71-5.15v-6.53h-2.71V29.9h2.71v-4.48c0-2.14-1.08-4.03-2.71-5.16c-1.01-0.69-2.22-1.09-3.54-1.09H21.93
                                                c-3.45,0-6.24,2.79-6.24,6.25v74.84c0,3.45,2.79,6.24,6.24,6.24h34.24c-0.64,0.56-1.04,1.4-1.04,2.32v6.61H6.74
                                                c-2.14,0-3.88,1.73-3.88,3.88v7.53h132.5v-7.53C135.36,117.17,133.63,115.44,131.48,115.44z M24.79,98c-0.9,0-1.63-0.73-1.63-1.64
                                                V26.75c0-0.9,0.72-1.64,1.63-1.64h88.36c0.9,0,1.64,0.73,1.64,1.64v69.61c0,0.9-0.73,1.64-1.64,1.64H24.79z"/>
                                            <path id="Box_x5F_Button_x5F_1" d="M127.43,72.21c0,1.25-1.01,2.24-2.24,2.24c-1.24,0-2.24-1-2.24-2.24
                                                c0-1.24,1.01-2.24,2.24-2.24C126.43,69.97,127.43,70.98,127.43,72.21z"/>
                                            <path id="Box_x5F_Button_x5F_2" d="M127.43,81.81c0,1.24-1.01,2.24-2.24,2.24c-1.24,0-2.24-1.01-2.24-2.24
                                                c0-1.24,1.01-2.24,2.24-2.24C126.43,79.57,127.43,80.57,127.43,81.81z"/>
                                            <path id="Food_x5F_Cover" d="M54.02,70.66H29.03c0.2-6.74,5.71-12.13,12.49-12.13C48.29,58.53,53.81,63.92,54.02,70.66z
                                                "/>
                                            <path id="Food_x5F_Cover_x5F_Handle" d="M43.16,56.08c0,0.91-0.74,1.65-1.65,1.65c-0.91,0-1.65-0.74-1.65-1.65
                                                c0-0.91,0.74-1.65,1.65-1.65C42.42,54.43,43.16,55.17,43.16,56.08z"/>
                                            <path id="Cup_x5F_Neck" d="M65.57,57.12v1.08c0,0.19-0.13,0.32-0.32,0.32h-10.9c-0.19,0-0.34-0.13-0.34-0.32v-1.08
                                                c0-0.2,0.15-0.34,0.34-0.34h10.9C65.44,56.78,65.57,56.91,65.57,57.12z"/>
                                            <path id="Cup_x5F_Cap" d="M66.87,48.14l-2.97,1.7l-0.42,2.78c1.08,0.88,1.79,2.14,1.82,3.54H54.28
                                                c0.08-2.66,2.53-4.8,5.51-4.8c0.99,0,1.94,0.24,2.75,0.66l0.4-2.66c0-0.03,0.02-0.05,0.03-0.07c0-0.02,0-0.02,0.02-0.02
                                                c0.02-0.05,0.07-0.12,0.13-0.15l3.24-1.87c0.24-0.13,0.56-0.05,0.69,0.19C67.19,47.68,67.11,47.98,66.87,48.14z"/>
                                            <path id="Cup_x5F_Body" d="M56.49,70.65h6.57l1.89-11.51H54.64L56.49,70.65z M57.65,62.1l2.14-1.23l2.14,1.23v2.46
                                                l-2.14,1.25l-2.14-1.25V62.1z"/>
                                            <path id="Tray" d="M68.35,72.29H26.12c-0.12,0-0.23-0.1-0.23-0.23v-0.59c0-0.12,0.1-0.23,0.23-0.23h42.23
                                                c0.12,0,0.23,0.1,0.23,0.23v0.59C68.58,72.19,68.48,72.29,68.35,72.29z"/>
                                            <path id="Line_x5F_1" d="M108.85,38.88H80.78c-0.27,0-0.49-0.22-0.49-0.49v-0.14c0-0.27,0.22-0.49,0.49-0.49h28.07
                                                c0.27,0,0.49,0.22,0.49,0.49v0.14C109.34,38.66,109.12,38.88,108.85,38.88z"/>
                                            <path id="Point_x5F_1" d="M78.03,38.88h-2c-0.27,0-0.49-0.22-0.49-0.49v-0.14c0-0.27,0.22-0.49,0.49-0.49h2
                                                c0.27,0,0.49,0.22,0.49,0.49v0.14C78.51,38.66,78.3,38.88,78.03,38.88z"/>
                                            <path id="Line_x5F_2" d="M108.85,41.99H80.78c-0.27,0-0.49-0.22-0.49-0.49v-0.14c0-0.27,0.22-0.49,0.49-0.49h28.07
                                                c0.27,0,0.49,0.22,0.49,0.49v0.14C109.34,41.77,109.12,41.99,108.85,41.99z"/>
                                            <path id="Point_x5F_2" d="M78.03,41.99h-2c-0.27,0-0.49-0.22-0.49-0.49v-0.14c0-0.27,0.22-0.49,0.49-0.49h2
                                                c0.27,0,0.49,0.22,0.49,0.49v0.14C78.51,41.77,78.3,41.99,78.03,41.99z"/>
                                            <path id="Line_x5F_3" d="M108.85,45.1H80.78c-0.27,0-0.49-0.22-0.49-0.49v-0.14c0-0.27,0.22-0.49,0.49-0.49h28.07
                                                c0.27,0,0.49,0.22,0.49,0.49v0.14C109.34,44.88,109.12,45.1,108.85,45.1z"/>
                                            <path id="Point_x5F_3" d="M78.03,45.1h-2c-0.27,0-0.49-0.22-0.49-0.49v-0.14c0-0.27,0.22-0.49,0.49-0.49h2
                                                c0.27,0,0.49,0.22,0.49,0.49v0.14C78.51,44.88,78.3,45.1,78.03,45.1z"/>
                                            <path id="Line_x5F_4" d="M108.85,48.2H80.78c-0.27,0-0.49-0.22-0.49-0.49v-0.14c0-0.27,0.22-0.49,0.49-0.49h28.07
                                                c0.27,0,0.49,0.22,0.49,0.49v0.14C109.34,47.98,109.12,48.2,108.85,48.2z"/>
                                            <path id="Point_x5F_4" d="M78.03,48.2h-2c-0.27,0-0.49-0.22-0.49-0.49v-0.14c0-0.27,0.22-0.49,0.49-0.49h2
                                                c0.27,0,0.49,0.22,0.49,0.49v0.14C78.51,47.98,78.3,48.2,78.03,48.2z"/>
                                            <path id="Line_x5F_5" d="M108.85,51.31H80.78c-0.27,0-0.49-0.22-0.49-0.49v-0.14c0-0.27,0.22-0.49,0.49-0.49h28.07
                                                c0.27,0,0.49,0.22,0.49,0.49v0.14C109.34,51.09,109.12,51.31,108.85,51.31z"/>
                                            <path id="Point_x5F_5" d="M78.03,51.31h-2c-0.27,0-0.49-0.22-0.49-0.49v-0.14c0-0.27,0.22-0.49,0.49-0.49h2
                                                c0.27,0,0.49,0.22,0.49,0.49v0.14C78.51,51.09,78.3,51.31,78.03,51.31z"/>
                                            <path id="Line_x5F_6" d="M108.85,54.42H80.78c-0.27,0-0.49-0.22-0.49-0.49v-0.14c0-0.27,0.22-0.49,0.49-0.49h28.07
                                                c0.27,0,0.49,0.22,0.49,0.49v0.14C109.34,54.2,109.12,54.42,108.85,54.42z"/>
                                            <path id="Point_x5F_6" d="M78.03,54.42h-2c-0.27,0-0.49-0.22-0.49-0.49v-0.14c0-0.27,0.22-0.49,0.49-0.49h2
                                                c0.27,0,0.49,0.22,0.49,0.49v0.14C78.51,54.2,78.3,54.42,78.03,54.42z"/>
                                            <path id="Button_x5F_1" d="M88.67,80.4H76.26c-0.38,0-0.69-0.31-0.69-0.69v-2.69c0-0.38,0.31-0.69,0.69-0.69h12.41
                                                c0.38,0,0.69,0.31,0.69,0.69v2.69C89.35,80.09,89.05,80.4,88.67,80.4z"/>
                                            <path id="Button_x5F_2" d="M98.69,80.4H91.3c-0.38,0-0.69-0.31-0.69-0.69v-2.69c0-0.38,0.31-0.69,0.69-0.69h7.39
                                                c0.38,0,0.69,0.31,0.69,0.69v2.69C99.38,80.09,99.07,80.4,98.69,80.4z"/>
                                            <path id="Button_x5F_3" d="M108.72,80.4h-7.39c-0.38,0-0.69-0.31-0.69-0.69v-2.69c0-0.38,0.31-0.69,0.69-0.69h7.39
                                                c0.38,0,0.69,0.31,0.69,0.69v2.69C109.41,80.09,109.1,80.4,108.72,80.4z"/>
                                            <path id="Dollar_x5F__x40_" d="M89.36,64.64v-3.67c0,0,0.98,0.52,0.89,1.77h1.42c0,0,0-2.65-2.32-3.31v-1.51h-1.09v1.2
                                                c0,0-0.75-0.18-1.7-0.07v-1.13h-1.1v1.37c0,0-2.64,0.66-2.29,3.45c0,0,0.07,1.73,2.32,2.42v4.26c0,0-1.44-0.74-1.45-2.33h-1.4
                                                c0,0-0.07,2.86,2.81,3.84v1.45h1.16v-1.23c0,0,0.38,0.21,1.66,0v1.23h1.12V71c0,0,2.75-0.53,2.61-3.9
                                                C92,67.1,91.98,65.41,89.36,64.64z M85.48,63.62c0,0-1.05-0.31-0.94-1.58c0,0,0-0.81,0.94-1.26V63.62z M88.27,69.78
                                                c0,0-0.7,0.17-1.69,0v-4.37c0,0,1.37,0.35,1.69,0.41V69.78z M88.27,64.42c0,0-1.54-0.32-1.69-0.47v-3.46c0,0,0.5-0.21,1.69,0.06
                                                V64.42z M90.59,67.7c0,1.42-1.23,1.8-1.23,1.8v-3.32C90.73,66.65,90.59,67.7,90.59,67.7z"/>
                                            <path id="Dollar_x5F_1" d="M100.42,64.64v-3.67c0,0,0.98,0.52,0.89,1.77h1.42c0,0,0-2.65-2.32-3.31v-1.51h-1.09v1.2
                                                c0,0-0.75-0.18-1.7-0.07v-1.13h-1.1v1.37c0,0-2.64,0.66-2.29,3.45c0,0,0.07,1.73,2.32,2.42v4.26c0,0-1.44-0.74-1.45-2.33h-1.4
                                                c0,0-0.07,2.86,2.81,3.84v1.45h1.16v-1.23c0,0,0.38,0.21,1.66,0v1.23h1.12V71c0,0,2.75-0.53,2.61-3.9
                                                C103.06,67.1,103.03,65.41,100.42,64.64z M96.53,63.62c0,0-1.05-0.31-0.94-1.58c0,0,0-0.81,0.94-1.26V63.62z M99.33,69.78
                                                c0,0-0.7,0.17-1.69,0v-4.37c0,0,1.37,0.35,1.69,0.41V69.78z M99.33,64.42c0,0-1.54-0.32-1.69-0.47v-3.46c0,0,0.5-0.21,1.69,0.06
                                                V64.42z M101.64,67.7c0,1.42-1.23,1.8-1.23,1.8v-3.32C101.78,66.65,101.64,67.7,101.64,67.7z"/>
                                        </svg>                       
                        }
                        
                        // Inventory Icon
                        else if (moduleId == 4) {
                            moduleIcon = <svg className='as-local-svg' viewBox="0 0 145.85 135.81">
                                            <path d="M125.52,25.24,67.45,7l-9,2.82L9.37,25.24H2.08v4.58H3.42v96.34H106.76v-6.93H10.07v-61H125.52V89.36H132V29.82h.78V25.24ZM39.92,49.59a1.33,1.33,0,0,1-1.34,1.33H20.38A1.35,1.35,0,0,1,19,49.59v-8.3A1.36,1.36,0,0,1,20.38,40H38.61a1.35,1.35,0,0,1,1.31,1.34Zm25.84,0a1.34,1.34,0,0,1-1.34,1.33H46.2a1.33,1.33,0,0,1-1.34-1.33v-8.3A1.35,1.35,0,0,1,46.2,40H64.44a1.35,1.35,0,0,1,1.34,1.34Zm25.82,0a1.34,1.34,0,0,1-1.34,1.33H72a1.31,1.31,0,0,1-1.33-1.32h0v-8.3A1.33,1.33,0,0,1,72,40H90.26a1.35,1.35,0,0,1,1.34,1.34Zm25.82,0a1.33,1.33,0,0,1-1.33,1.33H97.84a1.36,1.36,0,0,1-1.35-1.33v-8.3A1.38,1.38,0,0,1,97.84,40h18.23a1.33,1.33,0,0,1,1.35,1.33h0Z" transform="translate(-2.08 -7.04)" />
                                            <path d="M103.51,108.2v9.69a1.33,1.33,0,0,1-1.32,1.34h-21a1.33,1.33,0,0,1-1.31-1.34V108.2a1.35,1.35,0,0,1,1.33-1.35h.43v4.88h2.24v-4.91h18.21a1.35,1.35,0,0,1,1.38,1.31Z" transform="translate(-2.08 -7.04)" />
                                            <path d="M75.63,91.51v9.7a1.31,1.31,0,0,1-1.29,1.34h-21A1.32,1.32,0,0,1,52,101.24a0,0,0,0,1,0,0v-9.7a1.33,1.33,0,0,1,1.33-1.34h.79v4.9h2.31v-4.9H74.3a1.34,1.34,0,0,1,1.34,1.33Z" transform="translate(-2.08 -7.04)" />
                                            <path d="M75.63,108.2v9.69a1.31,1.31,0,0,1-1.29,1.34h-21A1.32,1.32,0,0,1,52,117.92a0,0,0,0,1,0,0V108.2a1.33,1.33,0,0,1,1.32-1.35h.8v4.88h2.31v-4.91H74.3a1.34,1.34,0,0,1,1.34,1.33A.09.09,0,0,1,75.63,108.2Z" transform="translate(-2.08 -7.04)" />
                                            <path d="M47.47,108.2v9.69a1.33,1.33,0,0,1-1.34,1.34H25.2a1.33,1.33,0,0,1-1.34-1.34V108.2a1.34,1.34,0,0,1,1.34-1.35h.72v4.88h2.31v-4.91h17.9a1.37,1.37,0,0,1,1.35,1.36Z" transform="translate(-2.08 -7.04)" />
                                            <path d="M47.47,91.51v9.7a1.33,1.33,0,0,1-1.34,1.34H25.2a1.33,1.33,0,0,1-1.34-1.34v-9.7a1.35,1.35,0,0,1,1.34-1.34h.72v4.9h2.31v-4.9h17.9A1.35,1.35,0,0,1,47.47,91.51Z" transform="translate(-2.08 -7.04)" />
                                            <path d="M47.47,74.42v9.69a1.34,1.34,0,0,1-1.34,1.36H25.2a1.34,1.34,0,0,1-1.34-1.36V74.42a1.35,1.35,0,0,1,1.34-1.34h.72V78h2.31v-4.9h17.9A1.35,1.35,0,0,1,47.47,74.42Z" transform="translate(-2.08 -7.04)" />
                                            <path d="M147.92,101.41v36.84a4.6,4.6,0,0,1-4.61,4.6H113.63a4.59,4.59,0,0,1-4.59-4.6h0V101.41a4.59,4.59,0,0,1,4.59-4.6h3.93v2.4h-4.23A2.3,2.3,0,0,0,111,101.5h0v36.66a2.29,2.29,0,0,0,2.29,2.28h30.26a2.27,2.27,0,0,0,2.29-2.27h0V101.5a2.28,2.28,0,0,0-2.26-2.29H139.4v-2.4h3.92A4.61,4.61,0,0,1,147.92,101.41Z" transform="translate(-2.08 -7.04)" />
                                            <path d="M120.3,107.26a4.77,4.77,0,1,0,4.76,4.77h0A4.77,4.77,0,0,0,120.3,107.26Zm3.07,3.26-2.65,3.37h0l-.34.43-.07.06a.19.19,0,0,1-.07.06.28.28,0,0,1-.11.08.67.67,0,0,1-.81,0l-1.93-1.59a.69.69,0,0,1-.12-1,.71.71,0,0,1,.57-.27.79.79,0,0,1,.44.16l1.4,1.16,2.63-3.35a.71.71,0,0,1,1-.12.75.75,0,0,1,.27.55A.77.77,0,0,1,123.37,110.52Z" transform="translate(-2.08 -7.04)" />
                                            <path d="M136.45,95.73h-3.89a3.91,3.91,0,0,0-.52-2,4.06,4.06,0,0,0-3.58-2.12,4.11,4.11,0,0,0-4.1,4.1h-3.87a.77.77,0,0,0-.78.77h0v4.36a.79.79,0,0,0,.79.79h16a.78.78,0,0,0,.78-.79V96.46a.77.77,0,0,0-.82-.73Zm-6,0a2,2,0,0,1-2,1.94,2,2,0,0,1-1.6-.81,1.92,1.92,0,0,1-.38-1.13h0a2,2,0,1,1,4,0Z" transform="translate(-2.08 -7.04)" />
                                            <path d="M141.29,110.73v.17a.75.75,0,0,1-.74.77H128.2a.76.76,0,0,1-.77-.77v-.17a.76.76,0,0,1,.77-.76h12.33a.76.76,0,0,1,.76.75Z" transform="translate(-2.08 -7.04)" />
                                            <path d="M141.29,116.26v.17a.75.75,0,0,1-.75.76h-9.33a.77.77,0,0,1-.77-.76v-.17a.79.79,0,0,1,.77-.77h9.31a.77.77,0,0,1,.77.77Z" transform="translate(-2.08 -7.04)" />
                                            <path d="M141.29,121.81V122a.75.75,0,0,1-.74.77H128.2a.76.76,0,0,1-.77-.77v-.17a.76.76,0,0,1,.77-.76h12.33a.76.76,0,0,1,.76.75Z" transform="translate(-2.08 -7.04)" />
                                            <path d="M141.29,127.37v.17a.76.76,0,0,1-.76.77h-9.32a.78.78,0,0,1-.77-.77v-.17a.79.79,0,0,1,.77-.77h9.31a.77.77,0,0,1,.77.77Z" transform="translate(-2.08 -7.04)" />
                                            <rect x="113.42" y="125.12" width="25.8" height="1.7" rx="0.77" />
                                            <path d="M120.3,119.44a4.8,4.8,0,1,0,4.33,6.77,4.32,4.32,0,0,0,.43-2A4.77,4.77,0,0,0,120.3,119.44Zm3.07,3.26-2.65,3.37h0l-.31.39s0,.05-.07.06a.19.19,0,0,1-.07.06.28.28,0,0,1-.11.08.66.66,0,0,1-.81,0l-.66-.54-1.27-1a.69.69,0,0,1-.12-1,.71.71,0,0,1,.57-.27.79.79,0,0,1,.44.16l1.4,1.16,2.63-3.35a.71.71,0,0,1,1-.12.75.75,0,0,1,.27.55.72.72,0,0,1-.22.56Z" transform="translate(-2.08 -7.04)" />
                                            <line x1="104.69" y1="119.12" x2="104.69" y2="112.19" />
                                            <line x1="129.97" y1="82.32" x2="123.45" y2="82.32" />
                                        </svg>
                        }
                        
                        // Account Icon
                        else if (moduleId == 5) {
                            moduleIcon = <svg className='as-local-svg' viewBox="0 0 146.78 147.31">
                                            <polygon points="0 50.13 18.78 37.23 18.78 13.82 36.77 13.82 36.77 24.94 73.14 0 146.78 50.58 138.61 62.2 73.39 17.51 8.14 62.2 0 50.13"/>
                                            <path d="M75,35a56.81,56.81,0,1,0,56.82,56.81A56.8,56.8,0,0,0,75,35Zm12.76,92.9v8.54h-6.9v-7.59c-7.93,1.3-10.27,0-10.27,0v7.59H63.42v-9c-17.77-6-17.33-23.72-17.33-23.72h8.63c.08,9.83,9,14.4,9,14.4V91.88C49.8,87.66,49.37,77,49.37,77,47.21,59.71,63.51,55.65,63.51,55.65V47.21h6.81v7a33,33,0,0,1,10.53.44V47.21h6.73v9.31c14.31,4,14.31,20.44,14.31,20.44h-8.8a11.57,11.57,0,0,0-5.51-11V88.69c16.12,4.74,16.29,15.18,16.29,15.18C104.74,124.65,87.75,127.93,87.75,127.93Z" transform="translate(-1.61 -1.35)"/>
                                            <path d="M95.16,107.58a11.47,11.47,0,0,1-7.58,11.13V98.17C96,101.12,95.16,107.58,95.16,107.58Z" transform="translate(-1.61 -1.35)"/>
                                            <path d="M80.85,95.94v24.48a28.45,28.45,0,0,1-10.44,0v-27S78.86,95.59,80.85,95.94Z" transform="translate(-1.61 -1.35)"/>
                                            <path d="M80.85,63.34v24s-9.49-2-10.44-2.93V63S73.51,61.69,80.85,63.34Z" transform="translate(-1.61 -1.35)"/>
                                            <path d="M63.6,64.88V82.39s-6.47-1.89-5.79-9.74C57.81,72.65,57.81,67.65,63.6,64.88Z" transform="translate(-1.61 -1.35)"/>
                                        </svg>
                        }

                        // Costing Icon
                        else if (moduleId == 6) {
                            moduleIcon = <svg className='as-local-svg' viewBox="0 0 138.25 147.04">
                                            <path d="M77.92,60.74H10.52a4.65,4.65,0,0,0-4.64,4.65v78.48a4.65,4.65,0,0,0,4.64,4.65h67.4a4.65,4.65,0,0,0,4.65-4.65V65.39A4.65,4.65,0,0,0,77.92,60.74ZM30.26,142.48H12.12V130.39H30.26Zm0-16.8H12.12v-12.1H30.26Zm0-16.8H12.12V96.78H30.26Zm23,33.6H35.15V130.39H53.29Zm0-16.8H35.15v-12.1H53.29Zm0-16.8H35.15V96.78H53.29Zm23,33.6H58.18v-28.9H76.33Zm0-33.6H58.18V96.78H76.33Zm0-22.07a2.65,2.65,0,0,1-2.65,2.64H14.76a2.64,2.64,0,0,1-2.64-2.64V70.27a2.64,2.64,0,0,1,2.64-2.63H73.68a2.64,2.64,0,0,1,2.65,2.63Z" transform="translate(-5.88 -1.48)"/>
                                            <rect x="59.36" y="79.3" width="4.78" height="4.78" />
                                            <rect x="50.23" y="79.3" width="4.79" height="4.78" />
                                            <rect x="41.11" y="79.3" width="4.79" height="4.78" />
                                            <path d="M123.58,26.89A36.46,36.46,0,0,0,69.93,57.12H80.46a5.28,5.28,0,0,1,5.28,5.29V89.09a36.45,36.45,0,1,0,37.84-62.2ZM114.5,82.16v5.48h-4.43V82.76c-5.08.84-6.58,0-6.58,0v4.88h-4.6V81.88C87.49,78,87.77,66.67,87.77,66.67h5.54c0,6.3,5.75,9.24,5.75,9.24V59c-8.91-2.7-9.19-9.56-9.19-9.56C88.5,38.4,99,35.79,99,35.79V30.38h4.37v4.48a21,21,0,0,1,6.75.28V30.38h4.32v6c9.18,2.6,9.18,13.12,9.18,13.12h-5.64a7.46,7.46,0,0,0-3.54-7V57c10.33,3,10.46,9.74,10.46,9.74C125.39,80.06,114.5,82.16,114.5,82.16Zm20.12-19.27c-1.05-.48-.77-2-.32-3.12.17-.39.32-.78.49-1.18l.37.56C135,60.41,134.81,61.65,134.62,62.89Zm-.41-5.77a3.12,3.12,0,0,0,.5-2.41,35.66,35.66,0,0,0-1.51-8.86c-.48-1.37-6.13-8.73-6.29-8.5,1.79-2.51,7.23,6.58,7.73,7.82,1.46,3.72,1.2,7.87.91,11.87Z" transform="translate(-5.88 -1.48)"/>
                                            <path d="M119.24,69.1a7.34,7.34,0,0,1-4.85,7.14V63.07C119.8,65,119.24,69.1,119.24,69.1Z" transform="translate(-5.88 -1.48)" />
                                            <path d="M110.07,61.64v15.7a18.2,18.2,0,0,1-6.7,0V60S108.8,61.42,110.07,61.64Z" transform="translate(-5.88 -1.48)" />
                                            <path d="M110.07,40.73V56.1s-6.08-1.27-6.7-1.88V40.5S105.36,39.67,110.07,40.73Z" transform="translate(-5.88 -1.48)" />
                                            <path d="M99,41.72V53s-4.15-1.21-3.71-6.26A5.92,5.92,0,0,1,99,41.72Z" transform="translate(-5.88 -1.48)" />
                                            <path d="M144.12,3.17q-5.1,8.49-10.22,17c-1.16,1.93-2.39,3.93-4.29,5.15a8.52,8.52,0,0,1-2.71,1.15,38.57,38.57,0,0,0-42.35.78c-.09,0-.16-.06-.24-.08a9.83,9.83,0,0,1-2.82-1.85A39.67,39.67,0,0,1,69.75,7.54c-.85-2.62-2.87-7.18,1.34-5.6,3.21,1.2,5.58,5.45,8.51,7.3A14.27,14.27,0,0,0,96,8.11C97.92,6.49,99.4,4.38,101.49,3a9.67,9.67,0,0,1,8.56-1c3.52,1.33,5.91,4.58,9,6.69a16.07,16.07,0,0,0,19.19-1.35C140.1,5.78,141.72,3.61,144.12,3.17Z" transform="translate(-5.88 -1.48)" />
                                        </svg>
                        }

                        //  Housekeeping Icon
                        else if (moduleId == 7) {
                            moduleIcon = <svg className='as-local-svg' viewBox="0 0 143.89 137.59">
                                            <polygon points="58.92 91.15 55.64 137.57 33.31 137.57 28.27 69.26 58.92 91.15" />
                                            <path d="M88.49,93.33c-1.31-.87-3.28,0-3.28,0l-8.76-7c3.28-3.07,0-9.42,0-9.42S67.47,53,63.09,42.54,43.59,31.8,43.59,31.8C28.72,29.4,19.07,44.5,19.07,44.5,14.7,44.28,9.87,34,9.87,34s-3.94-3.5-7.44-1.09S.24,39.47.24,39.47,4,46,10.76,56.33s12.7-.44,12.7-.44L81.05,97.5c-.89,3.07.65,3.72.65,3.72a26.14,26.14,0,0,0,5.48,5.48c6.35,32.62,31.31,30.87,31.31,30.87L138.63,101C109.51,107.14,89.81,94.22,88.49,93.33ZM60.68,74.94V66.53L64.4,77.35Zm45.77,43.59s5.08,7.39,11.39,8.09l-1.11,4.17s-15.54.43-22.11-22.78l2.63-3.72s6.35,5.47,29.57,5.26l-5,10.5S115.64,121.59,106.45,118.53Z" />
                                            <circle cx="55.38" cy="15.33" r="15.33" />
                                            <path d="M143.89,87.87a5.25,5.25,0,1,1-5.26-5.26A5.24,5.24,0,0,1,143.89,87.87Z" />
                                            <path d="M127.8,95.53a2.44,2.44,0,1,1-2.44-2.44A2.44,2.44,0,0,1,127.8,95.53Z" />
                                        </svg>
                        }

                        //  HR Icon
                        else if (moduleId == 8){
                            moduleIcon = <svg className='as-local-svg' viewBox="0 0 512 438.37">
                                            <path d="M280.48 121.98c-.62-5.11 14.54-24.64 17.32-34 5.94-9.42 8.04-24.43 1.57-34.4-2.57-3.96-1.47-7.39-1.47-12.86 0-54.28-95.1-54.3-95.1 0 0 6.85 1.57 8.47-2.13 13.89-6.23 9.01-3.03 25.02 2.23 33.4 3.37 9.84 19.27 28.43 18.17 33.99-1 27.86 59.58 26.94 59.41-.02zM114.5 383.61V225.86h70.96c30.08 5.39 60.15 21.71 90.24 40.64h55.11c24.95 1.49 38.03 26.78 13.76 43.41-19.31 14.18-44.82 13.37-70.97 11.02-18.03-.9-18.8 23.33 0 23.41 6.54.52 13.63-1.02 19.82-1.02 32.65-.05 59.48-6.28 75.93-32.05l8.25-19.26 81.98-40.64c41.02-13.5 70.16 29.4 39.96 59.26-59.4 43.2-120.28 78.75-182.58 107.46-45.23 27.51-90.48 26.57-135.69 0l-66.77-34.48zM0 210.7h93.13v188.76H0V210.7zm389.62-34.05h2.69c2.41 0 4.4-1.99 4.4-4.41v-7.12c0-2.41-1.99-4.4-4.4-4.4h-15.93c-2.41 0-4.4 1.99-4.4 4.4v7.12c0 2.42 1.99 4.41 4.4 4.41h2.73l-5.23 26.54h20.76l-5.02-26.54zm-27.86-38.74c.5-4.1-11.69-19.8-13.92-27.32-4.78-7.58-6.47-19.64-1.27-27.66 2.07-3.18 1.19-5.94 1.19-10.34 0-43.62 76.44-43.64 76.44 0 0 5.51-1.26 6.81 1.71 11.17 5 7.24 2.44 20.11-1.79 26.85-2.71 7.91-15.5 22.84-14.61 27.32.81 22.4-47.89 21.65-47.75-.02zm59.34 13.08c9.39 6.01 22.72 10.94 33.37 14.5 20.93 6.96 20.87 7.94 20.72 37.7h-73.94l19.85-52.2zm-175.29 19.18h-3.34c-3 0-5.46-2.48-5.46-5.48v-8.86c0-3.01 2.46-5.48 5.46-5.48h19.81c3.01 0 5.48 2.47 5.48 5.48v8.86c0 3-2.47 5.48-5.48 5.48h-3.38l6.49 33.02h-25.82l6.24-33.02zm114.24 33.02c-2.57-33.31 3.95-31.87-23.93-42.3-13.92-5.21-26.29-11.82-39.06-19.81l-23.93 62.11h86.92zm-153.39-64.94c-11.7 7.47-22.86 13.62-36.12 18.04-26.03 8.66-25.95 9.87-25.78 46.9h86.59l-24.69-64.94z" />
                                        </svg>
                        }else if(moduleId == 9){
                            moduleIcon=<svg className='as-local-svg' viewBox="0 0 512 512">
                                    <g>
                                        <g>
                                            <path d="M256,0C114.842,0,0,114.842,0,256s114.842,256,256,256s256-114.842,256-256S397.158,0,256,0z M194.27,34.43
                                                c-10.879,10.942-20.817,25.328-29.403,43.945c-6.558,14.217-12.228,30.589-16.964,46.343H67.21
                                                C97.373,81.395,142.101,49,194.27,34.43z M51.43,150.974h89.971c-6.111,28.882-9.67,60.39-10.408,91.897H26.607
                                                C28.398,210.051,37.15,179.856,51.43,150.974z M26.673,269.128h104.35c0.8,32.82,4.418,64.328,10.6,91.897H51.98
                                                C37.534,333.456,28.614,303.262,26.673,269.128z M67.933,387.282h80.266c4.676,17.067,10.245,31.772,16.666,45.693
                                                c8.413,18.238,18.206,32.647,28.936,43.817C142.251,462.276,97.995,430.605,67.933,387.282z M242.872,483.601
                                                c-24.944-8.623-51.007-42.493-67.465-96.319h67.465V483.601z M242.872,361.026H168.34c-6.236-26.256-10.214-57.764-11.062-91.897
                                                h85.593V361.026z M242.872,242.872h-85.613c0.776-31.508,4.54-63.015,11.001-91.897h74.612V242.872z M242.872,124.718H175.26
                                                c3.898-11.815,8.174-24.25,13.232-35.216c15.353-33.288,34.687-54.59,54.38-61.13V124.718z M485.393,242.872H381.007
                                                c-0.738-31.508-4.296-63.015-10.408-91.897h89.971C474.85,179.856,483.602,210.051,485.393,242.872z M444.79,124.718h-80.693
                                                c-4.737-15.754-10.405-31.996-16.963-46.213c-8.524-18.481-18.091-33.049-28.51-43.962
                                                C370.411,49.232,414.799,82.708,444.79,124.718z M269.128,28.652c19.692,6.875,38.816,28.047,53.946,60.849
                                                c5.058,10.966,9.767,23.401,13.665,35.216h-67.612V28.652z M269.128,150.974h74.612c6.46,28.882,10.226,60.39,11.001,91.897
                                                h-85.613V150.974z M269.128,269.128h85.593c-0.848,34.133-4.826,65.641-11.062,91.897h-74.531V269.128z M269.128,483.316v-96.034
                                                h67.465C320.319,439.795,295.385,474.221,269.128,483.316z M319.588,476.525c10.013-11.117,19.234-25.659,27.546-43.68
                                                c6.421-13.921,11.99-28.496,16.666-45.563h80.266C414.275,430.605,370.545,461.827,319.588,476.525z M460.02,361.026h-89.642
                                                c6.181-27.569,9.8-59.077,10.6-91.897h104.35C483.386,303.262,474.466,333.456,460.02,361.026z"/>
                                        </g>
                                    </g>
                                    </svg>
                        }
                        
                        return (
                            <li className="as-modules-lists" key={x.id}>
                                <Link 
                                    ref={el => ref_module.current[index] = el} 
                                    className={'as-modules-menu-link'+(activeModuleId==x.id?" as-active-menu":"")+(openModelId==index?" as-visible-menu ":"")}//Adding active module case.
                                    title={x.name}
                                    to={(x.link==undefined?"":x.link)}
                                    onClick={event => {
                                        event.preventDefault();

                                        //If trying to open another module
                                        if (index != openModelId) {
                                            thePOP();
                                            push(<MegaMenu key={x.id} moduleName={x.name} menuList={x.level_two} thePOP={thePOP} moduleId={x.id} updateActiveModuleId={setActiveModuleId} />);
                                            setOpenModelId(index);
                                        } else {//Trying to open / close own module
                                            if (modal.length > 0) {
                                                setOpenModelId(-1);
                                                thePOP();
                                            } else {
                                                thePOP();
                                                push(<MegaMenu key={x.id} moduleName={x.name} menuList={x.level_two} thePOP={thePOP} moduleId={x.id} updateActiveModuleId={setActiveModuleId} />);
                                                setOpenModelId(index);
                                            }
                                        }
                                    }}
                                >
                                    {moduleIcon}
                                    <span className='as-menu-name'>{x.name}</span>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    );
}


export default Sidebar;







// var menuName = x.name.toUpperCase();
// if (menuName == 'APPLICATION') {
//     var moduleIcon = <FontAwesomeIcon className='as-module-icon' icon={faCogs} />
// }
// else if (menuName == 'FRONT OFFICE') {
//     var moduleIcon = <FontAwesomeIcon className='as-module-icon' icon={faBusinessTime} />
// }
// else if (menuName == 'POINT OF SALE') {
//     var moduleIcon = <FontAwesomeIcon className='as-module-icon' icon={faGlassCheers} />
// }
// else if (menuName == 'INVENTORY') {
//     var moduleIcon = <FontAwesomeIcon className='as-module-icon' icon={faCubes} />
// }
// else if (menuName == 'ACCOUNT') {
//     var moduleIcon = <FontAwesomeIcon className='as-module-icon' icon={faHandHoldingUsd} />
// }